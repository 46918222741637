export enum INSTRUMENT {
    ACORDEON = 'acordeon',
    GUITARRA = 'guitarra',
    GUITARRA_VALLENATA = 'guitarra-curso-de-guitarra-guitarra-vallenata',
    GUITARRA_URBANA = 'guitarra-curso-de-guitarra-guitarra-urbana',
    PERCUSION_VALLENATA = 'percusion-vallenata',
    PIANO = 'piano',
    PERCUSION_VALLENATA_GUACHARACA = 'percusion-vallenata-cursos-de-percusion-vallenata-curso-de-guacharaca',

}
