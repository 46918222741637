import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  getAdditionalUserInfo,
  signInWithPopup,
  onAuthStateChanged,
} from 'firebase/auth'
import { firebaseConfig } from 'src/config/firebaseConfig'
import { success, error } from 'src/utils/toast'
import { sendCompleteRegistrationEvent } from 'src/utils/facebookPixel'
import { saveRegistrationLog } from './Auth'

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

// To activate emulators run: firebase emulators:start
// connectFirestoreEmulator(db, '127.0.0.1', 8080)
// connectAuthEmulator(auth, "http://127.0.0.1:9099")

interface IInsert<V> {
  collectionName: string
  value: V
}

export const insert = <V>({
  collectionName,
  value,
}: IInsert<V>) => {
  const createdAt = Timestamp.fromDate(new Date())
  const collectionRef = collection(db, collectionName)
  return addDoc(collectionRef, {
    ...value,
    createdAt,
  })
}

export const signInWithGoogle = async (cbLogin) => {
  const provider = new GoogleAuthProvider()
  const result = await signInWithPopup(auth, provider)
  if (result) {
    const { user }: any = result
    if (!!user) {
      const additionalUserInfo = getAdditionalUserInfo(result)
      const token = user.stsTokenManager.accessToken
      cbLogin(token, user)
      sendCompleteRegistrationEvent()
      if (additionalUserInfo.isNewUser) {
        await saveRegistrationLog({
          user: result.user.email,
          provider: additionalUserInfo.providerId,
        })
      }
      success('Bienvenido a FZ Academia')
    }
  }
}

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider()
  return signInWithRedirect(auth, provider)
}

export const getSignInResult = async (cbLogin, cbLoader) => {
  try {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime
        const token = await user.getIdToken()
        cbLogin(token)
        sendCompleteRegistrationEvent()
        if (isNewUser) {
          await saveRegistrationLog({
            user: user.email,
            provider: user.providerData[0].providerId,
          })
        }
        success('Bienvenido a FZ Academia')
      }
      cbLoader(false)
    })
  } catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      error(`Ya existe un usuario con la dirección de correo electrónico ${e?.email || ''}`)
    } else if (Object.keys(cbLoader).length > 0) {
      error('Ocurrio un error inesperado')
      await saveRegistrationLog({
        user: e?.email,
        provider: e?.credential?.providerId,
        failedError: JSON.stringify(e),
        isFailed: true,
      })
    }
    cbLoader(false)
  }
}

export const socialSignOut = async () => {
  await auth.signOut()
}
