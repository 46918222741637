import React, { FC } from 'react'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useSelector } from 'src/store/types'
import translateRole from 'src/utils/translateRole'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { logout } from 'src/store/modules/auth/actions'
import Link from 'next/link'
import { Badge, Icon } from '@mui/material'
import { useGetShoppingCart } from 'src/hooks/useShoppingCart'
import { MENU_LANDING, whatsappUrl } from 'src/config/constants/landing'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Image } from 'src/components/atoms/Image'
import { MenuMobile } from 'src/components/templates/Landing/MenuMobile'
import { changeOpenMobile } from 'src/store/modules/navigation/actions'
import { socialSignOut } from 'src/services/Firebase'
import UserMenu from '../UserMenu'
import NotificationsMenu from '../NotificationsMenu'

type StylesProps = {
  isLanding?: boolean
  isSideBarOpened?: boolean
  isMobileSideBarOpened?: boolean
}

// PASAR A REDUUUUUUUXXX
const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  logo: {
    cursor: 'pointer',
  },
  headerLeftIcon: {
    display: 'none',
    marginRight: 2,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  headerLeftIconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  headerRightIcons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .icon': {
      color: '#FFF',
      margin: 'unset',
      fontSize: 25,
    },
  },
  headerIconsDesktop: {
    [theme.breakpoints.down('md')]: {
      display: ({ isLanding }: StylesProps) => isLanding && 'none',
    },
  },
  headerDrawerOpened: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      zIndex: theme.zIndex.drawer,
      width: '100%',
    },
  },
  headerDrawerClosed: {
    width: ({ isLanding }: StylesProps) => (!isLanding ? 'calc(100% - 73px)' : '100%'),
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: ({ isLanding }: StylesProps) => !isLanding && '100%',
      zIndex: ({ isMobileSideBarOpened }: StylesProps) => isMobileSideBarOpened && 0,
    },
  },
  item: {
    textDecoration: 'none',
    margin: '0 15px',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}))

interface HeaderPrivateProps {
  isSideBarOpened?: boolean
  isLanding?: boolean
  handleSideBarToggle?: () => void
}

const HeaderPrivate: FC<HeaderPrivateProps> = ({ isSideBarOpened, isLanding = false, handleSideBarToggle }) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const user = useSelector(({ auth }) => auth.user)
  const isMobileSideBarOpened = useSelector(({ navigation }) => navigation.openMobile)
  const isStudent = user?.role === 'student'
  const classes = useStyles({ isLanding, isSideBarOpened, isMobileSideBarOpened })

  const shoppingCart = useGetShoppingCart({ enabled: isStudent })

  const handleToggleSideBarMobile = () => {
    dispatch(changeOpenMobile(!isMobileSideBarOpened))
  }

  const handleLogout = async () => {
    await dispatch(logout())
    await socialSignOut()
    await router.replace('/cursos')
  }

  let menuFiltered = MENU_LANDING.filter((item) => item.title !== 'Contacto')

  if (router.route === '/blog') menuFiltered = menuFiltered.filter((item) => item.title !== 'Blog')

  return (
    <AppBar
      position="fixed"
      className={clsx({ [classes.headerDrawerOpened]: isSideBarOpened, [classes.headerDrawerClosed]: !isSideBarOpened })}
    >
      <Toolbar>
        {
          !isLanding ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleSideBarToggle}
                className={classes.headerLeftIcon}
              >
                {isSideBarOpened ? <MenuIcon /> : <ChevronRightIcon />}
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleToggleSideBarMobile}
                className={clsx(classes.headerLeftIcon, classes.headerLeftIconMobile)}
              >
                {isSideBarOpened ? <MenuIcon /> : <ChevronRightIcon />}
              </IconButton>
            </>
          ) : (
            <Link href="/" passHref>
              <div className={classes.logo}>
                <Image
                  layout="fixed"
                  height={30}
                  width={170}
                  alt="logo"
                  src="/images/landing/logo-fondo-naranja.png"
                />
              </div>
            </Link>
          )
        }

        <div className={classes.headerRightIcons}>
          <div className={classes.headerIconsDesktop}>
            {
              isLanding && menuFiltered?.map((item, i) => (
                <Typography
                  key={i}
                  component="a"
                  href={item.url}
                  className={classes.item}
                >
                  {item.title}
                </Typography>
              ))
            }

            {
              isStudent && (
                <>
                  <Link href={whatsappUrl} passHref>
                    <a target="_blank">
                      <IconButton edge="end" className="icon" size="large">
                        <Icon>chat</Icon>
                      </IconButton>
                    </a>
                  </Link>

                  <Link href="/tienda/carrito" passHref>
                    <IconButton edge="end" className="icon" size="large">
                      <Badge badgeContent={shoppingCart?.cart?.length ?? 0} color="error">
                        <Icon>shopping_cart</Icon>
                      </Badge>
                    </IconButton>
                  </Link>
                </>
              )
            }

            <NotificationsMenu />

            <UserMenu
              handleLogout={handleLogout}
              image={user?.picture}
              name={user?.fullName}
              email={user?.email}
              role={translateRole(user?.role)}
              isVerified={user?.isVerified}
            />
          </div>

          {isLanding && <MenuMobile menu={MENU_LANDING} />}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderPrivate

/* // @packages
import { AppBar, Toolbar as ToolbarMaterial, IconButton, Icon, Hidden, Badge, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FunctionComponent, ReactNode } from 'react'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

// @store
import { useSelector } from 'src/store/types'

// @utils
import translateRole from 'src/utils/translateRole'
import { logout } from 'src/store/modules/auth/actions'

// @hooks
import { useGetShoppingCart } from 'src/hooks/useShoppingCart'

// @components
import UserMenu from '../UserMenu'
import NotificationsMenu from '../NotificationsMenu'
import { menuLanding } from 'src/config/constants/landing'

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: ({ isPublic, isLanding }: any) => (!isPublic && !isLanding ? 'calc(100% - 250px)' : 'calc(100% - 0)'),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 73px)',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: '#FFF',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    '& .icon': {
      color: '#FFF',
      margin: 'unset',
      fontSize: 25,
    },
  },
  item: {
    textDecoration: 'none',
    margin: '0 15px',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
}))

interface IToolbarProps {
  handleOpenMenu?: () => void
  handleChangeMini?: () => void
  mini?: boolean
  isLanding?: boolean
  children?: ReactNode
}

const HeaderPrivate: FunctionComponent<IToolbarProps> = ({
  handleOpenMenu,
  handleChangeMini,
  isLanding,
  mini,
}) => {
  const isPublic = useSelector(({ general }) => general.isPublic)
  const dispatch = useDispatch()
  const router = useRouter()
  const user = useSelector(({ auth }) => auth.user)
  const isStudent = user?.role === 'student'
  const shoppingCart = useGetShoppingCart({ enabled: isStudent })
  const classes = useStyles({ isPublic, isLanding })

  const handleLogout = async () => {
    await dispatch(logout())
    await router.replace('/cursos')
  }

  const menuFiltered = menuLanding.filter(item => item.title !== 'Contacto')

  return (
    <AppBar color="primary" position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: mini })}>
      <ToolbarMaterial className={classes.toolbar}>
        <div className="left">
          {
            !isLanding && (
              <>
                <Hidden implementation="css" mdUp>
                  <IconButton edge="start" onClick={handleOpenMenu} size="large">
                    <Icon className={classes.icon}>menu</Icon>
                  </IconButton>
                </Hidden>

                <Hidden implementation="css" mdDown>
                  <IconButton edge="start" onClick={handleChangeMini} size="large">
                    <Icon className={classes.icon}>menu</Icon>
                  </IconButton>
                </Hidden>
              </>
            )
          }
        </div>

        <div className={classes.icons}>
          {
            isLanding && menuFiltered?.map((item, i) => (
              <Typography
                key={i}
                component="a"
                href={item.url}
                className={classes.item}
              >
                {item.title}
              </Typography>
            ))
          }

          {
            isStudent && (
              <>
                <Link href="/contacto" passHref>
                  <IconButton edge="end" className="icon" size="large">
                    <Icon>chat</Icon>
                  </IconButton>
                </Link>

                <Link href="/tienda/carrito" passHref>
                  <IconButton edge="end" className="icon" size="large">
                    <Badge badgeContent={shoppingCart?.cart?.length ?? 0} color="error">
                      <Icon>shopping_cart</Icon>
                    </Badge>
                  </IconButton>
                </Link>
              </>
            )
          }

          <NotificationsMenu />
          <UserMenu
            handleLogout={handleLogout}
            image={user?.picture}
            name={user?.fullName}
            email={user?.email}
            role={translateRole(user?.role)}
            isVerified={user?.isVerified}
          />
        </div>
      </ToolbarMaterial>
    </AppBar>
  )
}

export default HeaderPrivate */
