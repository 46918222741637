// @packages
import React, { FC, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'

// @components
import { Card, CardContent, CardMedia, Popover } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from 'src/components/atoms/Typography'
import { Button } from 'src/components/atoms/Button'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { USER_NAVIGATION } from 'src/config/constants/landing'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  wrapper: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 10,
  },
  coursesButton: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  info: {
    cursor: 'pointer',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      right: -5,
    },
    '& svg': {
      width: 25,
    },
  },
  media: {
    padding: 10,
    minHeight: 350,
  },
  name: {
    padding: 0,
    margin: 0,
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 'calc(.6em + 1vw)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'calc(.8em + 1vw)',
    },
  },
  more: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',

    '& Button': {
      marginTop: '0',
      height: '40px',
      width: '160px',
      color: 'white',

      [theme.breakpoints.only('xs')]: {
        width: '100px',

        '& h6': {
          fontSize: 'calc(.6em + 1vw) !important',
        },
      },

    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        width: 30,
      },
    },
  },
  popover: {
    width: '100px',
    positon: 'relative',
    textAlign: 'center',
    fontSize: 16,
  },
}))

interface CourseCardProps {
  image: string
  name: string
  href: string
  seeMoreUrl: string
}

export const CourseCard: FC<CourseCardProps> = ({ image, name, href, seeMoreUrl }) => {
  const classes = useStyles()
  const { addItems } = useLocalStorage()

  const [handleOpenPopover, setHandleOpenPopover] = useState(null)

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>) => {
    setHandleOpenPopover(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setHandleOpenPopover(null)
  }

  const handleSaveRoute = () => {
    addItems(USER_NAVIGATION, '/')
  }

  return (
    <Card classes={{ root: classes.root }}>
      <CardMedia
        image={image}
        component="img"
        height="350"
        className={classes.media}
      />
      <CardContent classes={{ root: classes.container }}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <h5 className={classes.name}>{name}</h5>
            <Link href={href}>
              <Button className={classes.coursesButton} variant="contained" color="primary" onClick={handleSaveRoute}>
                Ver curso
              </Button>
            </Link>
          </div>

          <div
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className={classes.info}
          >
            {seeMoreUrl && (
              <Link href={seeMoreUrl}>
                <InfoIcon color="secondary" fontSize="large" />
              </Link>
            )}
            <Popover
              open={!!handleOpenPopover}
              anchorEl={handleOpenPopover}
              sx={{
                pointerEvents: 'none',
                zIndex: '1',
              }}
              anchorOrigin={{
                vertical: 0,
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 40,
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
            >
              <Typography className={classes.popover} variant="h6">
                Ver más
              </Typography>
            </Popover>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
