export const WHATSAPP_CONTACT = '573103521227'

export const USER_NAVIGATION = 'USER_NAVIGATION'

const whatsappUri = 'https://api.whatsapp.com/send/'
export const whatsappUrl = `${whatsappUri}?phone=${WHATSAPP_CONTACT}&text=Hola,+Indícanos+tu+nombre+y+el+instrumento+que+deseas+aprender.`

export const MENU_GLOBAL = [
  {
    title: 'Inicio',
    url: '/',
  },
  {
    title: 'Cursos',
    url: '/cursos',
  },
  {
    title: 'Contacto',
    url: whatsappUrl,
    newTab: true,
  },
  {
    title: 'Blog',
    url: '/blog',
  },
]

export const MENU_LANDING = [...MENU_GLOBAL]
MENU_LANDING.splice(1, 0, {
  title: 'Cómo funciona',
  url: '#about',
})

export const MENU_APP = [...MENU_GLOBAL]
export const MENU_BLOG = [...MENU_GLOBAL].filter((item) => item.title !== 'Blog')

export const SEE_MORE_URLS = {
  acordeon: 'como-tocar-acordeon-vallenato',
  guitarra: 'como-tocar-guitarra',
  percusion: 'como-tocar-caja-vallenata',
  percusionGuacharaca: 'como-tocar-guacharaca-vallenata',
  guitarraVallenata: 'como-tocar-guitarra-vallenata',
  guitarraUrbana: 'como-tocar-guitarra-urbana',
  piano: 'como-tocar-piano',
}
